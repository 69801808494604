import { useState, useEffect, useContext, useRef } from 'react';
import { useEventListener } from '../utils/customHooks';
import axios from 'axios';
import authContext from '../utils/authContext';
import { useNavigate } from 'react-router-dom';
import '../assets/css/Settings.css';

const dialogButtonReset = `Reset`;
const dialogButtonSso = `Deactivate`;

const dialogTitleReset = `Reset tenant`;
const dialogTitleSso = `Single sign-on deactivation`;

const dialogContentReset = `Are you sure you want to reset tenant? This will restore all settings to default state.`;
const dialogContentSso = `Removing the metadata URL will disable single sign-on the next time you log in.`;

const tab = {
    tenant: 'tenant',
    authentication: 'auth'
};

const action = {
    resetTenant: 'reset',
    saveChanges: 'save',
    disableSso: 'disable',
};

const aliasErrorMessages = [
    'The alias must contain at least five characters and can include digits, upper and lower case letters, and the hyphen (-).',
    'You cannot start your alias with a hyphen (-).',
    'The alias can contain a maximum of 50 characters.'
];

function SystemSettings() {
    const [isLoading, setIsLoading] = useState(false);
    const [screenLoading, setScreenLoading] = useState(true);
    const { setAuthenticated } = useContext(authContext);

    const [selectedTab, setSelectedTab] = useState(tab.tenant);
    const [buttonAction, setButtonAction] = useState(action.resetTenant);

    const [ssoActivated, setSsoActivated] = useState(false);
    const [initialSsoActivated, setInitialSsoActivated] = useState(false);
    const [ssoAlias, setSsoAlias] = useState('');
    const [idpMetadataUrl, setIdpMetadataUrl] = useState('');
    const [isGzAuthenticationEnabled, setIsGzAuthenticationEnabled] = useState(false);
    const [isGzAuthorizationEnabled, setIsGzAuthorizationEnabled] = useState(false);
    const [isInitialAuthenticationEnabled, setIsInitialAuthenticationEnabled] = useState(false);
    const [initialSsoAlias, setInitialSsoAlias] = useState('');
    const [initialIdpMetadataUrl, setInitialIdpMetadataUrl] = useState('');

    const [changedValues, setChangedValues] = useState({
        ssoAlias: null,
        idpMetadataUrl: null
    });

    const [disabledButton, setDisabledButton] = useState(false);
    const [aliasError, setAliasError] = useState('');
    const navigate = useNavigate();

    const ssoAliasRef = useRef(null);
    const idpMetadataUrlRef = useRef(null);
    const tenantTabRef = useRef(null);
    const authenticationTabRef = useRef(null);
    const saveSettingsBtnRef = useRef(null);
    const gzAuthToggleRef = useRef(null);
    const ssoToggleRef = useRef(null);

    useEffect(() => {
        if (selectedTab === tab.authentication) {
            const element = ssoAliasRef.current;
            element.addEventListener('change', handleSsoAlias);

            return () => {
                element.removeEventListener('change', handleSsoAlias);
            };
        }
    }, [selectedTab]);

    useEffect(() => {
        if (selectedTab === tab.authentication) {
            const element = idpMetadataUrlRef.current;
            element.addEventListener('change', handleIdpMetadataUrl);

            return () => {
                element.removeEventListener('change', handleIdpMetadataUrl);
            };
        }
    }, [selectedTab]);

    const createDialog = (title, content, button, linkedAction) => {
        const closeAfterSomeEvent = () => {
            dialog.dismiss();
        }

        const dialogBody = document.createElement('div');
        dialogBody.innerHTML = `<p>${content}</p>`;

        const footer = document.createElement('div');
        footer.innerHTML = `
            <gz-button id="confirmButton" color="negative">${button}</gz-button>
            <gz-button id="cancelButton" type="outline" color="secondary">cancel</gz-button>
        `;

        const dialog = window.nirvana.dependencies.services.buildDialogElement(
            {
                headline: title,
                bodyContent: dialogBody,
                isOpen: true,
                footerContent: footer,
                onCloseHandler: closeAfterSomeEvent,
            },
            document.querySelector('#dialog-wrapper')
        );

        footer.querySelector('#cancelButton').addEventListener('click', () => {
            dialog.dismiss();
        });

        footer.querySelector('#confirmButton').addEventListener('click', (e) => {
            dialog.close();
            dialog.emitDialogEvent('confirm');

            if (linkedAction === action.resetTenant) {
                resetSettings(e);
            } else if (linkedAction === action.disableSso) {
                ssoToggleRef.current.checked = false;
                ssoAliasRef.current.disabled = true;
                idpMetadataUrlRef.current.disabled = true;
                disableSso(e);
            }
        })
    }

    const openConfirmationDialog = (e) => {
        e.preventDefault();
        createDialog(dialogTitleReset, dialogContentReset, dialogButtonReset, action.resetTenant);
    }

    const resetSettings = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await axios({
                method: 'delete',
                url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
                withCredentials: true
            });

            toastNotification('Tenant successfully reset.', 'success');
            setAuthenticated(false);
            setIsLoading(false);
            navigate('/integrations');
        } catch (error) {
            toastNotification('The action failed.', 'error');
            setIsLoading(false);
        }
    }

    const disableSso = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            await axios({
                method: 'delete',
                url: process.env.REACT_APP_API_URL + '/api/sso',
                withCredentials: true
            });

            setButtonAction(action.saveChanges)

            toastNotification('SSO has been disabled.', 'success');
        } catch (error) {
            toastNotification(error.response.data, 'error');
        }

        setIsLoading(false);
    }

    const toastNotification = (message, severity) => {
        const toastNotificationConfigs = {
            message: message,
            severity: severity,
            x: 'right',
            y: 'bottom',
            autoCloseDelayMs: 10000,
        };

        window.nirvana.dependencies.services.buildToastNotification(toastNotificationConfigs);
    }

    const getCredentials = async () => {
        try {
            let response = await axios({
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/api/sso',
                withCredentials: true
            });

            setSsoAlias(response.data.ssoAlias);
            setInitialSsoAlias(response.data.ssoAlias);
            setIdpMetadataUrl(response.data.idpMetadataUrl);
            setInitialIdpMetadataUrl(response.data.idpMetadataUrl);
            setSsoActivated(response.data.isSsoEnabled);
            setInitialSsoActivated(response.data.isSsoEnabled);

            if (response.data.isSsoEnabled === true) {
                ssoAliasRef.current.disabled = false;
                idpMetadataUrlRef.current.disabled = false;
            } else {
                ssoAliasRef.current.disabled = true;
                idpMetadataUrlRef.current.disabled = true;
            }
        } catch {
            ssoAliasRef.current.disabled = true;
            idpMetadataUrlRef.current.disabled = true;
        }

        setIsLoading(false);
        setScreenLoading(false);
    }

    const getTenantInfo = async () => {
        let response = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/tenant',
            withCredentials: true
        });

        setIsGzAuthenticationEnabled(response.data.isGzAuthenticationEnabled);
        setIsGzAuthorizationEnabled(response.data.isGzAuthorizationEnabled);
        setIsInitialAuthenticationEnabled(response.data.isGzAuthenticationEnabled);

        if (response.data.isGzAuthorizationEnabled === true && response.data.isGzAuthenticationEnabled) {
            gzAuthToggleRef.current.disabled = true;
        }
    }

    const handleSsoAlias = (e) => {
        setSsoAlias(e.target.value);
        let items = changedValues;
        items.ssoAlias = e.target.value;
        setChangedValues(items);
    }

    const handleIdpMetadataUrl = (e) => {
        setIdpMetadataUrl(e.target.value);
        let items = changedValues;
        items.idpMetadataUrl = e.target.value;
        setChangedValues(items);

        if (e.target.value === '') {
            setButtonAction(action.disableSso);
        } else {
            setButtonAction(action.saveChanges);
        }
    }

    const inputError = (temp) => {
        if (temp.hasOwnProperty('ssoAlias')) {
            if (temp.ssoAlias !== '') {
                ssoAliasRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                ssoAliasRef.current.markAsValid();
            }
        }
        if (temp.hasOwnProperty('idpMetadataUrl')) {
            if (temp.idpMetadataUrl !== '') {
                idpMetadataUrlRef.current.markAsInvalid();
                setIsLoading(false);
            } else {
                idpMetadataUrlRef.current.markAsValid();
            }
        }
    }

    const validateForm = () => {
        let temp = {};
        const ssoAliasToValidate = (changedValues.ssoAlias == null && (ssoAlias !== null && ssoAlias !== '')) ? ssoAlias : changedValues.ssoAlias;
        const idpMetadataUrlToValidate = (changedValues.idpMetadataUrl == null && (idpMetadataUrl !== null && idpMetadataUrl !== '')) ? idpMetadataUrl : changedValues.idpMetadataUrl;

        if (changedValues.ssoAlias !== null || ssoActivated) {
            if (!/^[a-zA-Z0-9-]{5,}$/.test(ssoAliasToValidate)) {
                temp.ssoAlias = 'ssoAlias';
                setAliasError(aliasErrorMessages[0]);
            }
            else if (!/^[^-].*/.test(ssoAliasToValidate)) {
                temp.ssoAlias = 'ssoAlias';
                setAliasError(aliasErrorMessages[1]);
            }
            else if (!/^.{1,50}$/.test(ssoAliasToValidate)) {
                temp.ssoAlias = 'ssoAlias';
                setAliasError(aliasErrorMessages[2]);
            }
            else temp.ssoAlias = ''
        }

        if (changedValues.idpMetadataUrl !== null || ssoActivated) {
            temp.idpMetadataUrl = /(https:)+[^\s]+[\w]/.test(idpMetadataUrlToValidate) ? '' : 'idpMetadataUrl';
        }
        inputError(temp);

        return Object.values(temp).every(x => x === '');
    }

    const shouldBeButtonDissabled = () => {
        if (isInitialAuthenticationEnabled !== isGzAuthenticationEnabled) {
            return false;
        } else if (initialSsoActivated !== ssoActivated) {
            return false;
        } else if (changedValues.ssoAlias === initialSsoAlias && changedValues.idpMetadataUrl === initialIdpMetadataUrl) {
            return true;
        } else if (!Object.values(changedValues).every(x => x === null || x === '')) {
            return false;
        } else if (changedValues.ssoAlias === '' || changedValues.idpMetadataUrl === '') {
            return false;
        }
        return true;
    }

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        if (buttonAction === action.disableSso && ssoActivated && (changedValues.ssoAlias === '' && changedValues.idpMetadataUrl === '')) {
            createDialog(dialogTitleSso, dialogContentSso, dialogButtonSso, action.disableSso)
        }
        else {
            setIsLoading(true);
            let errors = [];

            let ssoData = {
                ssoAlias: ssoActivated === true && changedValues.ssoAlias !== null ? changedValues.ssoAlias : initialSsoAlias,
                idpMetadataUrl: changedValues.idpMetadataUrl !== null ? changedValues.idpMetadataUrl : initialIdpMetadataUrl,
            }

            if (validateForm()) {
                if (isInitialAuthenticationEnabled !== isGzAuthenticationEnabled) {
                    try {
                        await axios({
                            method: 'patch',
                            url: process.env.REACT_APP_API_URL + '/api/tenant/settings',
                            data: {
                                isGzAuthenticationEnabled: isGzAuthenticationEnabled,
                                isGzAuthorizationEnabled: isGzAuthorizationEnabled
                            },
                            withCredentials: true
                        });

                        setIsInitialAuthenticationEnabled(isGzAuthenticationEnabled);
                    } catch (error) {
                        if (error.response.status === 500) {
                            errors.push('The server encountered an unexpected error.');
                        } else {
                            errors.push(error.response.data);
                        }
                        setIsLoading(false);
                    }
                }

                if (!Object.values(ssoData).every(x => x === '')) {
                    try {
                        await axios({
                            method: 'post',
                            url: process.env.REACT_APP_API_URL + '/api/sso',
                            data: {
                                ...ssoData,
                                isSsoEnabled: ssoActivated
                            },
                            withCredentials: true
                        });
                    } catch (error) {
                        if (error.response.status === 409) {
                            ssoAliasRef.current.markAsInvalid();
                            errors.push(error.response.data);
                        } else if (error.response.status === 400) {
                            idpMetadataUrlRef.current.markAsInvalid();
                            errors.push(error.response.data);
                        }
                        else errors.push(error.response.data);
                    };
                }
            } else {
                errors.push('Validation error');
            }

            if (errors.length === 0) {
                toastNotification('Changes successfully saved.', 'success');
            } else if (errors.length === 1 && errors[0] === 'Validation error') {
            } else if (errors.length === 1) {
                toastNotification(errors[0], 'error');
            } else if (errors.length > 1) {
                toastNotification('The server encountered an unexpected error.', 'error');
            }
        }
        setIsLoading(false);
        setInitialSsoActivated(ssoActivated);
        setChangedValues({
            ssoAlias: null,
            idpMetadataUrl: null
        })
    }

    useEffect(() => {
        if (isLoading === true) {
            setDisabledButton(true);
        } else if (selectedTab === tab.authentication) {
            if (buttonAction === action.saveChanges) {
                setDisabledButton(shouldBeButtonDissabled());
            } else {
                setDisabledButton(false);
            }
        }
    }, [Object.values(changedValues), ssoActivated, isGzAuthenticationEnabled]);

    const getTenant = async () => {
        if (selectedTab === tab.authentication) {
            setIsLoading(true);
            try {
                await getTenantInfo();
                await getCredentials();
            } catch (error) {
                toastNotification(error.response.data, 'error');
            }
        }
    }

    useEffect(() => {
        getTenant();
    }, [selectedTab]);

    const handleTabChange = () => {
        if (tenantTabRef.current.hasAttribute('selected')) {
            setSelectedTab(tab.tenant);
            setButtonAction(action.resetTenant);
        }
        if (authenticationTabRef.current.hasAttribute('selected')) {
            setSelectedTab(tab.authentication);
            setButtonAction(action.saveChanges);
        }
    }

    const handleSsoToggle = (e) => {
        if (e.detail.checked === true) {
            setSsoActivated(true);
            ssoAliasRef.current.disabled = false;
            idpMetadataUrlRef.current.disabled = false;
        } else {
            setSsoActivated(false);
            ssoAliasRef.current.disabled = true;
            idpMetadataUrlRef.current.disabled = true;
            ssoAliasRef.current.markAsValid();
            idpMetadataUrlRef.current.markAsValid();

            ssoAliasRef.current.value = initialSsoAlias;
            idpMetadataUrlRef.current.value = initialIdpMetadataUrl;
            changedValues.ssoAlias = initialSsoAlias;
            changedValues.idpMetadataUrl = initialIdpMetadataUrl;
        }
    }

    const handleAuthToggle = (e) => {
        setIsGzAuthenticationEnabled(e.detail.checked);
    }

    useEventListener('click', e => handleSaveChanges(e), saveSettingsBtnRef.current);
    useEventListener('toggleChange', e => handleSsoToggle(e), ssoToggleRef.current);
    useEventListener('toggleChange', e => handleAuthToggle(e), gzAuthToggleRef.current);

    return (
        <div>
            <div className='p-4' id='main-div'>
                <gz-title fontsize="20" fontweight="500" style={{ opacity: '0.7' }}>System</gz-title>
                <div className='pt-3'>
                    <gz-tab-group onClick={handleTabChange}>
                        <gz-tab className='gz-tab-wrap' id='tenant-tab' label='Tenant' ref={tenantTabRef}></gz-tab>
                        <gz-tab className='gz-tab-wrap' id='authentication-tab' label='Authentication' ref={authenticationTabRef}></gz-tab>
                    </gz-tab-group>
                </div>
                <div id="dialog-wrapper"></div>

                {selectedTab === tab.tenant && <div>
                    <div className='pt-4 pb-3'>
                        <gz-button onClick={openConfirmationDialog} type="full" color="negative" buttonheight="medium" buttonfontsize="medium"
                            id='reset-tenant-btn' {...(isLoading === true ? { disabled: true } : {})}>
                            Reset Tenant
                        </gz-button>
                    </div>
                    <gz-text fontsize="13" lineheight="20">
                        All data will be deleted, including settings and integration details.<br />
                        After this action, you will have to reconfigure the tenant.
                    </gz-text>
                </div>}
                {selectedTab === tab.authentication &&
                    <div className='pt-4 pb-3 mb-3' style={{ overflowY: 'scroll', height: '70vh' }}>
                        <div className='center-div' style={{ visibility: screenLoading === true ? 'visible' : 'hidden' }}>
                            <gz-progress-spinner indeterminate="" spinnerwidth="medium"></gz-progress-spinner>
                        </div>
                        <div style={{ visibility: screenLoading === true ? 'hidden' : 'visible' }}>
                            <gz-title fontsize="18" fontweight="500" class="mb-2">GravityZone identity provider (IdP)</gz-title>
                            <div className='p-3 settings-sub-section mb-5'>
                                <div className='settings-inline'>
                                    <gz-toggle id="gz-authentication-toggle" gz-form-input="" errortooltiptext="" class='settings-toggle' ref={gzAuthToggleRef} {...(isGzAuthenticationEnabled === true) ? { checked: true } : {}}></gz-toggle>
                                    <gz-title fontsize="18" fontweight="500" class="pt-2">GravityZone authentication</gz-title>
                                </div>
                                <div className='pt-2 pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        This will enable/disable the login ability via GravityZone credentials for all users.<br /> If the GravityZone user token is enabled for authorization, the option cannot be turned off.
                                        <br />
                                    </gz-text>
                                </div>
                                <div>
                                    <div className='d-flex flex-row align-items-center'>
                                        <div class="p-1">
                                            <span className="ds-icon-validation_error" style={{ fontSize: '24px', color: '#ffa438', marginBottom: '10px' }}></span>
                                        </div>
                                        <div className='pb-1'>
                                            <gz-text fontsize="12" lineheight="21" fontweight="600">
                                                Warning:
                                            </gz-text>
                                        </div>
                                    </div>
                                </div>
                                <div className='pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        If you are setting up the integration for the first time, contact the Bitdefender Support team to obtain access to the <b>GravityZone user token</b> and the <b>Sign in with GravityZone</b> features. 
                                        <gz-link fontsize="12" href="https://www.bitdefender.com/business/support/en/77211-933458-enabling-sign-in-with-gravityzone-identity-provider-for-first-time-integrators.html" type="quiet" color="primary">
                                            Learn more
                                        </gz-link>
                                    </gz-text>
                                </div>
                            </div>
                            <gz-title fontsize="18" fontweight="500" class="mb-2">SAML identity provider (IdP)</gz-title>
                            <div className='p-3 settings-sub-section'>
                                <div className='settings-inline'>
                                    <gz-toggle id="sso-enabled-toggle" gz-form-input="" errortooltiptext="" class='settings-toggle' ref={ssoToggleRef}  {...(ssoActivated === true) ? { checked: true } : {}}></gz-toggle>
                                    <gz-title fontsize="18" fontweight="500" class="pt-2">Single sign-on</gz-title>
                                </div>
                                <div className='pt-2 pb-2'>
                                    <gz-text fontsize="12" lineheight="21">
                                        To configure single sign-on, refer to this&nbsp;
                                        <gz-link fontsize="13" href="https://www.bitdefender.com/business/support/en/77211-520962-configuring-datto-rmm-single-sign-on-with-an-identity-provider.html" type="quiet" color="primary">
                                            KB article.
                                        </gz-link>
                                        <br />
                                    </gz-text>
                                </div>
                                <div className='mt-3 mb-4'>
                                    <gz-form-field label="Alias" id="ssoAliasField">
                                        <gz-input
                                            gz-form-input
                                            type="text"
                                            errortooltiptext={aliasError}
                                            maxchars="100"
                                            value={ssoAlias}
                                            inputwidth="400"
                                            id='ssoAlias'
                                            ref={ssoAliasRef}>
                                        </gz-input>
                                    </gz-form-field>
                                    <gz-form-field label="Metadata URL" id="idpMetadataUrlField">
                                        <gz-input
                                            gz-form-input
                                            type="text"
                                            errortooltiptext="Invalid metadata URL."
                                            maxchars="100"
                                            value={idpMetadataUrl}
                                            inputwidth="400"
                                            id='idpMetadataUrl'
                                            ref={idpMetadataUrlRef}>
                                        </gz-input>
                                    </gz-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

            {selectedTab === tab.authentication &&
                <div className='page-footer fixed-bottom-navbar'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-3 navbar-light nav-bottom-shadow py-0' style={{ visibility: screenLoading === true ? 'hidden' : 'visible' }}>
                                <gz-button
                                    type="full"
                                    color="primary"
                                    buttonheight="medium"
                                    buttonfontsize="medium"
                                    class="py-3"
                                    id='save-settings-btn'
                                    {...(disabledButton === true) ? { disabled: true } : {}}
                                    ref={saveSettingsBtnRef}>
                                    SAVE CHANGES
                                </gz-button>
                            </div>
                            <div className='col-sm-7 nav-bottom-shadow py-2' >
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default SystemSettings;