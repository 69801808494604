import { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import authContext from '../utils/authContext';
import '../assets/css/Navbar.css';
import axios from 'axios';
import { EventContext } from '../App';
import connection from '../signalrConnection';
import logo from '../assets/images/logo.svg';
import { TENANT_STATUSES_MESSAGE } from '../utils/constants.js';

const links = {
    companies: '/companies',
    integration: '/integrations',
    defaultpackage: '/defaultpackage',
    system: '/system',
    events: '/events',
    inventory: '/inventory',
    sso: '/sso'
}

const Navbar = () => {
    const taskResults = useContext(EventContext);
    const [activeTab, setActiveTab] = useState(window.location.pathname);
    const [activeEvents, setActiveEvents] = useState('');
    const [defaultPackage, setDefaultPackage] = useState(false);
    const [inventorySync, setInventorySync] = useState(false);
    const [tenantStatus, setTenantStatus] = useState(false);

    const { authenticated, setAuthenticated } = useContext(authContext);

    const navigate = useNavigate();

    const logout = async () => {
        try {
            await axios({
                method: 'post',
                url: process.env.REACT_APP_API_URL + '/api/user/signOut',
                withCredentials: true
            })
            
            navigate('/login');
            setAuthenticated(false);
            connection.stop();
        } catch (error) {
            console.log(error.response.data);
        }
    }

    const retrieveEvents = async () => {
        const events = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/events',
            withCredentials: true
        });

        if (events.data.length !== 0) {
            setActiveEvents(events.data.length);
        } else {
            setActiveEvents('');
        }
    }

    const isNewUser = async () => {
        axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/api/tenant',
            withCredentials: true
        })
            .then(() => {
                setAuthenticated(true);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 404) {
                        if (error.response.data === TENANT_STATUSES_MESSAGE.invalidCredentials) {
                            setTenantStatus(true);
                        }
                        
                        if (error.response.data === TENANT_STATUSES_MESSAGE.firstTimeUseToken) {
                            setDefaultPackage(true);
                        } else {
                            setDefaultPackage(false);
                        }

                        setAuthenticated(false);
                        setInventorySync(false);
                        
                    }
                }
            });
    }

    useEffect(() => {
        if (authenticated) {
            retrieveEvents();
        } else {
            setActiveEvents('');
        }
    }, [taskResults, authenticated])

    useEffect(() => {
        isNewUser();
    }, [authenticated])

    useEffect(() => {
        setActiveTab(window.location.pathname);
        if (window.location.pathname === links.defaultpackage) {
            setDefaultPackage(true);
        }
        if(window.location.pathname === links.inventory) {
            setInventorySync(true);
        }

    }, [window.location.pathname])

    const isTabAvailable = (disableTab) => ({
        pointerEvents: (disableTab) ? 'initial' : 'none',

    });

    const isTabAvailableColor = (disableTab) => ({
        color: (disableTab) ? 'white' : '#b3b3b3'
    });

    const activateTab = (value, authenticated) => {
        if (value === true && authenticated === true) {
            return true;
        }
        if (value === true && authenticated === false) {
            return true;
        }
        if (value === false && authenticated === true) {
            return true;
        }
        if (value === false && authenticated === false) {
            return false;
        }
    }

    const settingsOptionRedirectAction = () => {
        let inventorySyncAvailable = activateTab(inventorySync, authenticated);
        if (inventorySyncAvailable === true) {
            return "/inventory";
        }

        return "/integrations";
    }

    const settingsActiveTab = () => {
        let inventorySyncAvailable = activateTab(inventorySync, authenticated);
        if (inventorySyncAvailable === true) {
            return setActiveTab(links.inventory);
        }
        return setActiveTab(links.integration);
    }

    return (
        <nav className='vertical-nav navbar-expand-sm navbar-dark navbar-size navbar-link' style={{backgroundColor: "#161e29"}}>
            <div className='pt-3'>
                <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navigationbar'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='logo-section'>
                    <img src={logo} alt='Bitdefender' className='mb-1' style={{width: '14vw'}} id='navbar-title'/>
                    <gz-title fontweight='600' fontsize='14' id='navbar-subtitle'>Datto RMM</gz-title>
                </div>
                
                <div className='collapse navbar-collapse' id='navigationbar'>
                    <ul className='navbar-nav flex-column mb-0 pt-3' style={{ width: "100%" }}>
                        <li className={'nav-item mt-3 px-2 ' + (activeTab === links.companies ? 'active' : '')}>
                            <Link to='/companies' className='nav-link nav-link-navbar' id='companies-link' onClick={() => setActiveTab(links.companies)} style={isTabAvailable(authenticated)}>
                                <i className="fa fa-building-o" style={isTabAvailableColor(authenticated)}></i>
                                <span className='ps-3' style={isTabAvailableColor(authenticated)}>Companies</span>
                            </Link>
                        </li>
                        <li className='nav-item mt-3 px-2'>
                            <Link to={settingsOptionRedirectAction()} className='nav-link nav-link-navbar' id='settings-link' onClick={() => settingsActiveTab()}>
                                <i className="fa fa-cog" style={{ color: 'white' }}></i>
                                <span className='ps-3 navbar-elements'>Settings</span>
                            </Link>
                        </li>
                        <li className={'nav-item ' + (activeTab === links.inventory ? 'active' : '')}>
                            <Link to='/inventory' className='nav-link nav-link-sub-navbar' id='inventory-settings-link' onClick={() => setActiveTab(links.inventory)} style={isTabAvailable(activateTab(inventorySync, authenticated))}>
                                <span className='ps-5' style={isTabAvailableColor((activateTab(inventorySync, authenticated)))}>Inventory Sync</span>
                            </Link>
                        </li>
                        <li className={'nav-item ' + (activeTab === links.integration ? 'active' : '')}>
                            <Link to='/integrations' className='nav-link nav-link-sub-navbar' id='integration-settings-link' onClick={() => setActiveTab(links.integration)}>
                                <span className='ps-5 navbar-elements'>Integration</span>
                            </Link>
                        </li>
                        <li className={'nav-item ' + (activeTab === links.defaultpackage ? 'active' : '')}>
                            <Link to='/defaultpackage' className='nav-link nav-link-sub-navbar' id='default-package-settings-link' onClick={() => setActiveTab(links.defaultpackage)} style={isTabAvailable(activateTab(defaultPackage, authenticated))}>
                                <span className='ps-5' style={isTabAvailableColor((activateTab(defaultPackage, authenticated)))}>Default Packages</span>
                            </Link>
                        </li>
                        <li className={'nav-item ' + (activeTab === links.system ? 'active' : '')}>
                            <Link to='/system' className='nav-link nav-link-sub-navbar' id='system-settings-link' onClick={() => setActiveTab(links.system)} style={isTabAvailable(authenticated || tenantStatus)}>
                                <span className='ps-5' style={isTabAvailableColor(authenticated || tenantStatus)}>System</span>
                            </Link>
                        </li>
                        <li className={'nav-item mt-3 px-2 ' + (activeTab === links.events ? 'active' : '')}>
                            <Link to='/events' className='nav-link nav-link-navbar' id='events-link' onClick={() => setActiveTab(links.events)} style={isTabAvailable(authenticated)}>
                                <i className="fa fa-wrench" style={isTabAvailableColor(authenticated)}></i>
                                <span className='ps-3' style={isTabAvailableColor(authenticated)}>Events <span className='navbar-events-number'>{activeEvents}</span></span>
                            </Link>
                        </li>
                        <li className='nav-item py-3 px-2 bottom-link'>
                            <Link to='/' className='nav-link nav-link-navbar' id='logout-link' onClick={logout} >
                                <i className="fa fa-power-off" style={{ color: 'white' }}></i>
                                <span className='ps-3 navbar-elements'>Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;