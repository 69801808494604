export const DROPDOWN_INVENTORY_DROPDOWN = [
    {
        label: 'Manual',
        value: false
    },
    {
        label: 'Automatic',
        value: true
    }
];

export const PREFIX_CREATION_DROPDOWN = [
    {
        label: 'Always',
        value: 0
    },
    {
        label: 'Only if the name already exists',
        value: 1
    }
];

export const TENANT_STATUSES_MESSAGE = {
    'notRegistered': 'Tenant is not registered',
    'notFound': 'Tenant not found',
    'invalidCredentials': 'Tenant is registered, but invalid credentials',
    'firstTimeUseToken': 'Tenant is registered, but first time login with token',
    'resetTenant': 'Tenant is reset, but no sign out'
}
